import React from 'react'
import Menu from '../../common/Menu/Menu'
import TopFooter from '../../common/TopFooter/TopFooter'
import Footer from '../../common/Footer/Footer'
import CommonBanner from '../../common/CommonBanner'

const Csr = () => {
  return (
    <>
      <Menu />
      <CommonBanner imageUrl={'/assets/img/sustainability.png'} title={"CSR"} subtext={""} />
      <div className='container' style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <h3 className='tp-section-title'>Corporate Social Responsibility (CSR) Policy</h3>
        <p>
          This CSR Policy has been framed by Alok Masterbatches Private Limited (“the Company”) in
          accordance with Section 135, Schedule VII of the Companies Act, 2013, and CSR Rules issued by
          the Ministry of Corporate Affairs on February 27, 2014. Unless the context otherwise requires, the
          definitions mentioned in the notification dated February 27, 2014, and the Companies Act 2013 shall
          apply to this CSR Policy.
        </p>

        <h2 className='tp-section-title'>Company Philosophy for CSR Policy</h2>
        <p>
          The Companies Act, 2013 formally introduced Corporate Social Responsibility to Indian
          Companies. The Government of India has given a separate section for CSR, providing legal
          recognition to their community development approach.
          </p><p>
          Alok Masterbatches Private Limited supports and appreciates the decision taken by the Government
          of India. The legal framework of CSR is an edge to corporate Charitable/Reformative approach
          towards the society around operations.
        </p><p>
          The management of Alok Masterbatches Private Limited expresses its willingness to and support for
          the CSR concept and its legal framework and shall abide by it.
        </p>

        <h2 className='tp-section-title'>Corporate Social Responsibility Committee</h2>
        <p>The Company has constituted a Corporate Social Responsibility Committee (“the Committee”)
          comprising the following members:</p>
        <ul>
          <li>Mr. Chandrapal Singh Bhadauria: Director</li>
          <li>Mr. Krishan Lal Puri: Director (Chairperson)</li>
          <li>Mr. Amit Puri: Director</li>
        </ul>
        <p>Mr. Krishan Lal Puri (director) is hereby appointed as the Chairperson of the Committee.
          The Board of Directors of the Company may reconstitute the Committee, as and when required to
          do so, by following the sections, sub-sections, rules, regulations, and notifications issued or to be
          issued, from time to time, by the Ministry of Corporate Affairs or the Central Government of India.
          The Committee shall exercise powers and perform the functions assigned to it by the Board of
          Directors of the Company pursuant to section 135 of the Companies Act, 2013 and the CSR Rules
          notified concerning it</p>

        <h2 className='tp-section-title'>The responsibilities of the CSR Committee include the following:</h2>
        <ul>
          <li>Formulate CSR strategy, policy, and goals.</li>
          <li>To monitor the AMPL CSR policy and performance.</li>
          <li>To review the CSR projects/initiatives including the amount of expenditure from time to
            time.</li>
          <li>To formulate and recommend to the Board for its approval, an annual action plan every
            financial year as per the recommended format of the Ministry of Corporate Affairs and will
review from time to time.</li>
          <li>To ensure legal and regulatory compliance as per CSR guidelines.</li>
          <li>To ensure reporting and communication to stakeholders on CSR projects/initiatives.</li>
        </ul>

        <h2 className='tp-section-title'>CSR Activities</h2>
        <ul>
          <li>Construction of Educational Building, hospital development & providing health care services</li>
          <li>Promoting health care including preventive health care activities like Medical Camp as per clause
            (i) Schedule VII of Companies Act,2013.</li>
          <li>Supporting services for value education Programs for students & parents etc.</li>
          <li>Providing donation to PM National Relief Fund & PM Care Fund & Namami Ganga project</li>
          <li>Community Health- Innovative projects to meet special needs & bridge the gap in basic health
            care accessibility.</li>
          <li>Contribution to incubators or research and development projects in the field of science,
            technology, engineering, and medicine, funded by the Central Government, State
            Government, Public Sector Undertaking or any agency of the Central Government or State
            Government.</li>
        </ul>
        <p>Alok Masterbatches Private Limited may, subject to the recommendation of the CSR Committee and
          approval from the Board of Directors, undertake any other CSR activity as specified in Schedule VII
          of the Act from time to time.</p>

        <h2 className='tp-section-title'>Budget for CSR Activity & CSR Expenses:</h2>
        <p>
          The Company shall allocate the budget for CSR activities. The minimum budgeted amount for a
          financial year shall be 2% of the average net profit of the three immediately preceding financial years.
          The Company may allocate more funds than the amount prescribed under section 135 of the2
          Companies Act, 2013, for the CSR activities for any financial year. The Committee shall calculate the
          total fund for the CSR activities and recommend them to the Board for approval. The Board shall
          approve the total fund to be utilized for CSR activity for the respective financial year.
        </p>
        <p>CSR expenditure shall include all expenditures including contributions to the corpus for projects or
          programs relating to CSR activities approved by the Board on the recommendation of its CSR
          Committee but does not include any expenditure on an item not in conformity or not in line with
          activities which fall within the approved CSR activities.</p>

        <h2 className='tp-section-title'>Identification of activities/ projects:</h2>
        <p>
          Out of the approved CSR activities, the Committee shall decide which activity/ project should be
          prioritized for the respective financial year. While arriving at the decision of the activity to be undertaken
          for the respective year, the Committee shall analyze the basic need of the community/ area in which
          the Organization operates or at the place where its registered office is situated. The Committee shall
          record its findings and prioritize the CSR activities.
        </p>

        <h2 className='tp-section-title'>Implementation process:</h2>
        <p>
          After prioritizing the activity, the Committee shall finalize the detailed implementation
          project/program, including planning for expenses against the total budget allocated for CSR activities.
        </p>
        <h2 className='tp-section-title'>Implementing Agencies:</h2>
        <p>The approved CSR activities, projects and programs shall be carried out directly or in any of the
          following ways as the Board, on the recommendation of the CSR Committee in its absolute discretion,
          may decide:</p>
          <ul>
            <li>
            A company established under section 8 of the Act, or a registered public trust or a registered
            society registered under section 12A and 80 G of the Income Tax Act, 1961 (43 of 1961),
            established by the company either singly or along with any other company.
          </li><li> A company established under section 8 of the Act or a registered trust or a registered society
            established by the Central Government or State Government; or any entity established under
            an Act of Parliament or a State legislature.
          </li><li> A company established under section 8 of the Act, or a registered public trust or a registered
            society, registered under section 12A and 80G of the Income Tax Act, 1961, and has an
            established track record of at least three years in undertaking similar activities.
          </li><li> The above-referred agencies shall have the unique CSR Registration Number.
            </li>
          </ul>
        <p>The Company may also undertake CSR projects or activities in collaboration with other corporates.
          The implementing organization will also be screened based on the Alok Masterbatches Private
          Limited screening criteria to ascertain the organization's credibility and its ability to execute the project
          effectively.</p>

        <h2 className='tp-section-title'>Monitoring by the Board:</h2>
        <p>The Board of Directors shall constantly monitor the implementation of the CSR activities. The CSR
          committee shall place a progress report, including details of expenses, before the Board quarterly.
          The Board shall review the same and suggest recommendations, if any, to the committee about the
          implementation process.</p>

        <h2 className='tp-section-title'>Monitoring of CSR projects:</h2>
        <p>All CSR Projects/Activities shall be monitored directly by the Board and the CSR Committee through
          the CSR Team. The Board certifies that the funds disbursed for CSR projects or activities have been
          utilized for the said purpose.</p>

        <h2 className='tp-section-title'>Management Commitment:</h2>
        <p>The Board of Directors, Management, and all employees subscribe to the philosophy of
          compassionate care. We believe and act on an ethos of generosity and compassion, characterized by
          a willingness to build a society that works for everyone. This is the cornerstone of our CSR policy.
        </p><p>
          Our Corporate Social Responsibility policy conforms to the relevant section of the Corporate Social
          Responsibility, Rules made under the Companies Act, 2013 and amendment(s) to be made thereto
          in the future.
          </p><p>
          The Company is contributing to sustainable development through its economic activities combined
          with the fulfillment of its social responsibilities relating to education, health, safety, and environmental
          aspects.</p>

        <h2 className='tp-section-title'>Organizational Responsibility:</h2>
        <p>At the organizational level for the implementation of the agreed CSR activity, the committee may
          constitute an implementation team or authorize any of the departments of the Company to organize3
          the implementation of the CSR activity. The team or respective department shall monitor the
          implementation process from time to time on behalf of the CSR committee and place a report to the
          Committee regarding the progress of the activity implementation on a quarterly basis. The respective
          department or implementation team would be responsible for reporting any irregularity to the
          Committee immediately.</p>
      </div>
      <TopFooter />
      <Footer />
    </>
  )
}

export default Csr